import { Container } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import HamburgerMenu, { MenuItemTypes, MenuItemUnionType } from '@components/HamburgerMenu/HamburgerMenu';
import DiscoverCompaniesDropdown from '@components/NavbarAbTestAtm4635/DiscoverCompaniesDropdown/DiscoverCompaniesDropdown';
import NavbarAbTestAtm4635HamburgerMenuToggle from '@components/NavbarAbTestAtm4635/NavbarAbTestAtm4635HamburgerMenuToggle';
import CompanySearch from '@components/utilities/CompanySearch/CompanySearch';
import Counter from '@components/utilities/Counter/Counter';
import Gradient from '@components/utilities/Gradient/Gradient';
import Logo from '@components/utilities/Logo/Logo';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import { useUser } from '@hooks/useUser';
import SearchSvg from '@icons/search.svg';
import pushGAInteractionEvent from '@libs/ga/pushGAInteractionEvent';
import { useGenerateRoute, useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import classes from './NavbarAbTestAtm4635.module.scss';
import { InterestingProfilesGalleryController } from '../../generated/phpConstants';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const NavbarAbTestAtm4635 = ({ className, ...divProps }: Props) => {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const { t } = useTranslation(['messages', 'profile', 'front']);
  const getLocalizedRoute = useGetLocalizedRoute();
  const generateRoute = useGenerateRoute();
  const { isMobile, isDesktop } = useActiveBreakpoint();
  const user = useUser();
  const router = useRouter();

  useEffect(() => {
    if (!isMobile) {
      setIsMobileSearchOpen(false);
    }
  }, [isMobile]);

  const getLoginWithPasswordRouteWithTargetPath = (): string => {
    if (router.pathname === Route.loginWithPassword) {
      return getLocalizedRoute(Route.loginWithPassword);
    }

    return getUrlWithParameters(getLocalizedRoute(Route.loginWithPassword), { targetPath: router.asPath });
  };

  const hamburgerMenuItems: MenuItemUnionType[] = [];

  if (!isDesktop) {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: (
        <div>
          {t('CHECK_OUT_THESE_COMPANIES')}
          <Counter>{InterestingProfilesGalleryController.FULL_GALLERY_PROFILES_COUNT}</Counter>
        </div>
      ),
      url: getUrlWithParameters(getLocalizedRoute(Route.interestingProfileGallery), {
        zdroj: 'menu',
        premium: InterestingProfilesGalleryController.COUNT_OF_PREMIUM_COMPANIES_TO_SHOW,
      }),
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('COMPANY_LIST_MENU_LINK_TITLE'),
      url: getLocalizedRoute(Route.companyList),
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('PROFILE_MATCHING_MENU_LINK_TITLE'),
      url: getUrlWithParameters(getLocalizedRoute(Route.profileMatchingResult), {
        zdroj: 'menu',
      }),
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('ADD_REVIEW', { ns: 'profile' }),
      url: generateRoute('front.questionnaire.basic', { zdroj: 'atmoskop-web', typ: 'atmoskop-web' }),
      rel: 'nofollow',
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Line,
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('GO_TO_JOB_DESCRIPTIONS_LIST', { ns: 'messages' }),
      url: getLocalizedRoute(Route.jobDescriptionList),
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('COMPANY_INFO_MENU_LINK_TITLE'),
      url: generateRoute('front.premiumLandingPage.premiumLandingPage'),
    });
  }

  if (user) {
    if (hamburgerMenuItems.length > 0) {
      hamburgerMenuItems.push({
        type: MenuItemTypes.Line,
      });
    }

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: user.email!,
      url: '',
      disabled: true,
    });
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('RECOMMENDED_COMPANIES'),
      url: getLocalizedRoute(Route.profileMatchingResult),
      rel: 'nofollow',
    });
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('MY_SUBSCRIBED_COMPANIES'),
      url: getLocalizedRoute(Route.userAccountSubscriptions),
    });
    if (user?.isB2bContactPerson) {
      hamburgerMenuItems.push({
        type: MenuItemTypes.Link,
        title: t('COMPANY_ADMINISTRATION'),
        url: getLocalizedRoute(Route.b2bAdminMonthlyReport),
      });
    }
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('LOGOUT'),
      url: generateRoute('front.logout'),
      isLinkPrimary: true,
      onClick: () => {
        pushGAInteractionEvent('user.logout', 'user', 'user.logout', 'window.atmoskop.userId');
      },
      dataCyItem: 'logout-button',
    });
  } else {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('LOGIN_BUTTON_LABEL'),
      url: getLoginWithPasswordRouteWithTargetPath(),
      isLinkPrimary: true,
      dataCyItem: 'login-button',
      rel: 'nofollow',
    });
  }

  return (
    <div {...divProps} className={classNames(classes.Navbar, className)}>
      <Gradient />
      <Container>
        <div className={classNames(classes.Navbar__content)}>
          <NextLink href={generateRoute('homepage')}>
            <Logo />
          </NextLink>
          <div className={classes.Navbar__contentMenuWithSearch}>
            {!isMobileSearchOpen && (
              <button
                type="button"
                className={classNames(classes.Navbar__button, classes.Navbar__buttonSearch, 'mr-500')}
                onClick={() => setIsMobileSearchOpen((prevState) => !prevState)}
              >
                <SearchSvg />
              </button>
            )}
            <div
              className={classNames(
                classes.Navbar__searchWrapper,
                isMobileSearchOpen && classes.Navbar__searchWrapperExpanded,
              )}
            >
              <CompanySearch handleSetIsSearchOpen={setIsMobileSearchOpen} isMobileSearchActive={isMobileSearchOpen} />
            </div>
            <div className="d-none d-desktop-flex align-items-center mr-tablet-700">
              <DiscoverCompaniesDropdown />
            </div>
            <div className="d-none d-desktop-flex align-items-center mr-tablet-700">
              <NextLink href={getLocalizedRoute(Route.jobDescriptionList)} className="link-secondary">
                {t('GO_TO_JOB_DESCRIPTIONS_LIST')}
              </NextLink>
            </div>
            <div className="d-none d-desktop-flex align-items-center mr-tablet-700">
              <NextLink href={getLocalizedRoute(Route.informationForCompanies)} className="link-secondary">
                {t('COMPANY_INFO_MENU_LINK_TITLE')}
              </NextLink>
            </div>
          </div>
          <div className="d-desktop-flex align-items-center mr-desktop-700">
            <HamburgerMenu menuItems={hamburgerMenuItems} icon={<NavbarAbTestAtm4635HamburgerMenuToggle />} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NavbarAbTestAtm4635;
